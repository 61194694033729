import { trackTradeCancelledGA, trackTradeExecutionGA, trackTradeFailedGA, trackTradeSuccessGA } from '@/app/analytics'
import { sendTransaction } from '@/app/helpers/sendTransaction'
import { useLavarage } from '@/app/providers/LavarageProvider'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { JUPITER_PLATFORM_FEE_BPS } from '@/config'
import { type LavarageService, type LavarageV2Service, referralService, tephrasBonusService, type TokenInfo } from '@/services'
import { jupiterSource } from '@/services/sources'
import { getPda } from '@/utils'
import { type PriceResponse } from '@lavarage/entities'
import { useWallet } from '@solana/wallet-adapter-react'
import { Keypair, PublicKey } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useNftAccess } from '../../../hooks/useNftAccess.js'
import { type TradeDetailsGA } from '../MainPage.js'

type UseBorrowProps = {
  solAmount: number
  baseTokenAmount: number | null
  toUSDC: PriceResponse['data'] | null
  baseToken: TokenInfo
  total: number
  slippage: number
  leverage: number
  setAmountFromIx: (arg0: number) => void
  tradeDetailsGA: TradeDetailsGA
}

export const useBorrow = ({ solAmount, baseTokenAmount, toUSDC, slippage, total, baseToken, leverage, setAmountFromIx, tradeDetailsGA }: UseBorrowProps) => {
  const lavarages = useLavarage()
  const [quoteToken] = usePreferencesStore(useShallow(s => [s.quoteToken]))
  // console.log('quoteToken?.symbol === SOL', quoteToken?.symbol === 'SOL')
  const lavarage = quoteToken?.symbol === 'SOL' ? lavarages[0] as LavarageService : lavarages[1] as LavarageV2Service
  const wallet = useWallet()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const referralCode = searchParams.get('ref') ?? searchParams.get('referrer') ?? undefined
  const navigate = useNavigate()
  const [addAlert, removeAlert, setLoading, setConfirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.setLoading, state.setConfirming]))
  const { hasLavaRockNFT } = useNftAccess()

  const borrow = useCallback(async () => {
    if (!lavarage?.program.provider.publicKey) {
      console.error('Provider public key is not available.')
      return
    }
    if (!baseTokenAmount) {
      console.error('Base token amount is not available.')
      return
    }
    if (!wallet.publicKey) {
      console.error('Wallet public key is not available.')
      return
    }
    if (!quoteToken) {
      console.error('Quote token is not available.')
      return
    }

    try {
      trackTradeExecutionGA(tradeDetailsGA)
      setLoading(true)
      setConfirming(true)
      const { publicKey: poolKey, nodeWallet, apr } = await lavarage.getPoolByTokenAddress(baseToken.address, quoteToken.address)

      if (!poolKey) {
        throw new Error('Pool not found')
      }
      const poolPubKey = new PublicKey(poolKey)
      const keypair = Keypair.generate()

      const positionAccountPDA = getPda([
        Buffer.from('position'),
        lavarage.program.provider.publicKey?.toBuffer(),
        poolPubKey.toBuffer(),
        keypair.publicKey.toBuffer(),
      ])

      const tokenAddressPubKey = new PublicKey(baseToken?.address)

      const toTokenAccount = await lavarage.getTokenAccountOrCreateIfNotExists(positionAccountPDA, tokenAddressPubKey)
      if (!toTokenAccount.account) {
        throw new Error('To token account not found')
      }
      const instructionsJup = await jupiterSource.getSwapIx(
        quoteToken.address,
        baseToken?.address,
        BigNumber(leverage * solAmount).times(10 ** quoteToken?.decimals),
        slippage,
        lavarage.program.provider.publicKey?.toBase58(),
        toTokenAccount.account.address.toBase58(),
        hasLavaRockNFT,
      )
      console.log('instructionsJup', instructionsJup)
      const outAmountFromIx = Number(instructionsJup.quoteResponse.outAmount) / 10 ** baseToken?.decimals
      setAmountFromIx(outAmountFromIx)

      const nameOfWallet = wallet.wallet?.adapter.name ?? 'Unknown'

      const tx = await lavarage.openBorrowingPosition(
        // Note: we can't use the baseTokenAmount and the price in this formula because it is not fixed yet. We will only know the actual result after the tix is executed. Use this in the meantime
        hasLavaRockNFT ? leverage * solAmount : leverage * solAmount * (1 - JUPITER_PLATFORM_FEE_BPS / 10_000),
        baseToken?.address,
        solAmount,
        instructionsJup,
        keypair,
        poolKey,
        nodeWallet,
        apr.multipliedBy(100).plus(1).toNumber(),
      )
      const txDetailsForSentry = {
        type: 'Trade',
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: nameOfWallet,
        tokenSymbol: baseToken?.symbol,
        tokenAddress: baseToken?.address,
        amount: solAmount,
        leverage,
        marginAmount: (1 - leverage) * solAmount,
        total,
        slippage,
      }
      const result = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, txDetailsForSentry)
      if (result) {
        setConfirming(false)
        trackTradeSuccessGA(tradeDetailsGA)
        referralService.registerReferee(wallet.publicKey?.toBase58(), referralCode, true)
        tephrasBonusService.saveBonusInfo(wallet.publicKey?.toBase58(), nameOfWallet, keypair.publicKey.toBase58())
        navigate({ pathname: '/positions', search: location.search })
      }
    }
    catch (error) {
      if (error instanceof Error && error.message === 'User rejected the request.') {
        trackTradeCancelledGA(tradeDetailsGA)
        return
      }
      else {
        trackTradeFailedGA({ ...tradeDetailsGA, error })
      }
      console.error('Error during borrowing:', error)
    }
    finally {
      setConfirming(false)
      setLoading(false)
      setAmountFromIx(0)
    }
  }, [lavarage, baseTokenAmount, toUSDC, baseToken?.address, solAmount, slippage, addAlert, setLoading, setConfirming, removeAlert, wallet, navigate])

  return { borrow }
}
