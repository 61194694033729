import { type CloseModalButtonMode, CloseModalTabs } from '@/app/pages/positions/components'

type GetCloseModalButtonModeProps = {
  isOnline: boolean
  currentTab: CloseModalTabs
  confirming: boolean
  available: number | null
  borrowedAmount: number
  isRestricted?: boolean
}

export const getCloseModalButtonMode = ({
  isOnline,
  currentTab,
  confirming,
  available,
  borrowedAmount,
  isRestricted,
}: GetCloseModalButtonModeProps): CloseModalButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case confirming:
      return 'confirming'
    case isRestricted || available === null:
      return 'restricted'
    case available !== null && borrowedAmount > available && currentTab === CloseModalTabs.REPAY:
      return 'insufficient-funds'
    case available !== null && borrowedAmount <= available && !confirming && currentTab === CloseModalTabs.REPAY:
      return 'repay'
    case !confirming && currentTab === CloseModalTabs.SELL:
      return 'sell'
    default:
      return 'restricted'
  }
}
