import { Conditional } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePreferencesStore } from '@/app/stores'
import { IconX, IconTradingMode } from '@/assets/svgs'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Modal } from './Modal'
import { motion } from 'framer-motion'

type ModeSelectionModalProps = { disabled?: boolean }
export const ModeSelectionModal = ({ disabled }: ModeSelectionModalProps) => {
  const [tradeMode, setTradeMode] = usePreferencesStore(useShallow(state => [state.tradeMode, state.setTradeMode]))
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsModalVisible(false)
      }
    },
    [isModalVisible],
  )

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener)
    }
  }, [isModalVisible, handleClickOutside])

  const toggleModal = () => {
    if (disabled) return
    setIsModalVisible(!isModalVisible)
  }

  useEffect(() => {
    if (isModalVisible) {
      const targetElement = wrapperRef.current
      if (targetElement) disableBodyScroll(targetElement)
      return () => {
        if (targetElement) enableBodyScroll(targetElement)
      }
    }
  }, [isModalVisible])

  const modes = ['long', 'short', 'borrow'] as const

  return (
    <div className='flex justify-end'>
      <button className={`${disabled ? 'cursor-default' : 'btn-icon'} flex items-center justify-end self-center`} onClick={toggleModal}>
        <IconTradingMode />
        <span className='ml-[3px] text-sm capitalize'>
          {tradeMode}
        </span>
      </button>

      <Conditional if={isModalVisible}>
        <Modal className='overflow-hidden'>
          <div ref={wrapperRef} className='bg-vibrant w-10/12 max-w-[380px] rounded-lg p-[10px] pb-5'>
            <div className='mb-[10px] flex justify-between'>
              <span className='py-[2.5px] text-base'>Select Trading Mode</span>
              <button className='btn-icon' onClick={toggleModal}>
                <IconX/>
              </button>
            </div>
            <div className='text-main flex justify-between gap-[10px] text-sm'>
              {modes.map((mode) => (
                <motion.button
                  key={mode}
                  className={clsxm(
                    'flex-1 h-11 cursor-pointer',
                    'items-center justify-center',
                    'bg-main rounded-lg bg-opacity-10 transition',
                    'capitalize',
                    { 'bg-opacity-30': tradeMode === mode }
                  )}
                  onClick={() => setTradeMode(mode)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  animate={{ 
                    backgroundColor: tradeMode === mode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {mode}
                </motion.button>
              ))}
            </div>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
}