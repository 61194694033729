import { Conditional, LSpan, NumericTokenInput, SlippageModal, TokenImage, UsdSwitch } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePoolsQuery } from '@/app/hooks/queries'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { Layout } from '@/app/pages/layout/Layout'
import { useBorrow } from '@/app/pages/main/hooks/useBorrow'
import { useSwap } from '@/app/pages/main/hooks/useSwap'
import { getActionMainButtonMode } from '@/app/pages/main/utils/getActionMainButtonMode'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { CRYPTO_DECIMAL_POINTS, FIAT_DECIMAL_POINTS, JUPITER_PLATFORM_FEE_BPS, MIN_LEVERAGE, MIN_PLATFORM_FEE_IN_SOL, PLATFORM_FEE, SOL_ADDRESS, SOL_LOGO_URI, USDC_ADDRESS } from '@/config'
import { coreService } from '@/services/CoreService'
import { getRelatedPool, stringToNumber } from '@/utils'
import { formatCurrency, formatSol } from '@/utils/formatters'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { IS_MULTI_CURRENCY_ENABLED } from '../../app.config.js'
import { Hint } from '../../components/Hint.js'
import { LabelledValue } from '../../components/LabelledValue.js'
import { ModeSelectionModal } from '../../components/ModeSelectionModal.js'
import { useNftAccess } from '../../hooks/useNftAccess.js'
import { usePriceContext } from '../../providers/PriceProvider.js'
import { ActionMainButton, LeverageField, LoanSummary, TokenList } from './components'
import { QuoteTokenList } from './components/QuoteTokenList.js'
import { checkWhitelisted } from './utils/checkWhitlisted.js'

export type TradeDetailsGA = {
  leverage: number
  quoteAmount: number
  baseAmount: number | null
  token: string
  fee: number
  total: number
  positionSize: number
  dailyInterest: number
  entryPrice: number | null
  liquidationPrice: number
}

type QuoteTokenSymbol = 'USDC' | 'SOL'

export function MainPage() {
  const [leverage, defaultLeverage, baseToken, slippage, solInput, setSolInput, isUsdModeOn, setIsUsdModeOn, tradeMode, setTradeMode, quoteToken, setBaseToken, setQuoteToken] = usePreferencesStore(useShallow(s => [
    s.leverage,
    s.defaultLeverage,
    s.baseToken,
    s.slippage,
    s.solInput,
    s.setSolInput,
    s.isUsdModeOn.trading,
    () => s.setIsUsdModeOn({ ...s.isUsdModeOn, trading: !s.isUsdModeOn.trading }),
    s.tradeMode,
    s.setTradeMode,
    s.quoteToken,
    s.setBaseToken,
    s.setQuoteToken,
  ]))
  // setBaseToken(defaultTokens.base)
  // const defaultTokens = {
  //   quote: { address: SOL_ADDRESS, symbol: 'SOL', name: 'Solana', logoURI: SOL_LOGO_URI, decimals: 9, tags: [], isNewCoin: false, isTopTrendingCoins: false, whitelisted: true, availableLiquidity: null, dailyInterest: null },
  //   base: { address: USDC_ADDRESS, symbol: 'USDC', name: 'USD Coin', logoURI: USDC_LOGO_URI, decimals: 6, tags: [], isNewCoin: false, isTopTrendingCoins: false, whitelisted: true, availableLiquidity: null, dailyInterest: null },
  // }
  // useEffect(() => {
  //   setQuoteToken(defaultTokens.quote)
  //   if (quoteToken === null) {
  //     setQuoteToken(defaultTokens.quote)
  //   }
  //   if (baseToken === null) {
  //     setBaseToken(defaultTokens.base)
  //   }
  //   if (quoteToken?.address === baseToken?.address) {
  //     setBaseToken(defaultTokens.base)
  //     setQuoteToken(defaultTokens.quote)
  //   }
  // }, [])
  if (!baseToken || !quoteToken) return
  const getUSDCPrice = (tokenAddress: string) => {
    return tokenAddress === USDC_ADDRESS ? BigNumber(1) : BigNumber(priceUsdc[tokenAddress]?.price)
  }
  const { priceSol, priceUsdc, solPriceLoading, solPriceError, usdcPriceLoading, usdcPriceError, refresh } = usePriceContext()
  const [amountFromIx, setAmountFromIx] = useState(0)
  // const amountFromIx = 0
  // const [a, setAmountFromIx] = useState(0)
  const { hasLavaRockNFT } = useNftAccess()
  const decimalPoints = isUsdModeOn ? FIAT_DECIMAL_POINTS : CRYPTO_DECIMAL_POINTS
  const [loading, confirming] = useAlertsStore(useShallow(state => [state.loading, state.confirming]))
  const { userBalance, connected, connecting } = useBalance()
  // console.log('userBalance', userBalance)
  const isWhitelisted = checkWhitelisted(baseToken, quoteToken.symbol)
  const currentLeverage = isWhitelisted ? (leverage ?? defaultLeverage ?? MIN_LEVERAGE.toString()) : MIN_LEVERAGE.toString()
  const isOnline = useNetworkStatus()
  const [maxLeverage, setMaxLeverage] = useState<number | null>(null)

  const qtPrice = quoteToken?.address !== SOL_ADDRESS ? 1 / priceUsdc[SOL_ADDRESS]?.price : priceSol[SOL_ADDRESS]?.price

  const isBaseTokenAmountLoading = solPriceLoading || usdcPriceLoading

  const openFee = coreService.openFee({ baseCurrency: stringToNumber(solInput) || 0, leverage: parseFloat(currentLeverage), fee: PLATFORM_FEE })
  const isMinFee = openFee < MIN_PLATFORM_FEE_IN_SOL
  const fees = isMinFee ? MIN_PLATFORM_FEE_IN_SOL : openFee
  const jupiterPlatformFee = hasLavaRockNFT ? 0 : (stringToNumber(solInput) || 0) * parseFloat(currentLeverage) * JUPITER_PLATFORM_FEE_BPS / 10_000

  const getPriceBaseTokenInQuoteToken = (amount: number) => {
    if (isBaseTokenAmountLoading) return null
    if (quoteToken?.symbol === 'USDC') {
      return priceUsdc[baseToken?.address]?.price ? priceUsdc[baseToken?.address]?.price * amount : null
    }
    if (quoteToken?.symbol === 'SOL') {
      return priceSol[baseToken?.address]?.price ? priceSol[baseToken?.address]?.price * amount : null
    }
    return null
  }
  const getBaseTokenAmount = () => {
    if (isBaseTokenAmountLoading) return null
    const spentAmountInSwap = stringToNumber(solInput) * parseFloat(currentLeverage) - jupiterPlatformFee
    if (quoteToken?.symbol === 'USDC') {
      return spentAmountInSwap / Number(getUSDCPrice(baseToken?.address)) || null
    }
    if (quoteToken?.symbol === 'SOL') {
      return spentAmountInSwap * priceSol[SOL_ADDRESS]?.price || null
    }
    return null
  }
  const baseTokenAmount = getBaseTokenAmount()
  const availableFunds = userBalance?.[quoteToken?.symbol as QuoteTokenSymbol] || null
  const maxAvailableFunds = availableFunds ? availableFunds - fees : 0
  const isLeverageEqualToOne = parseFloat(currentLeverage) === 1 || !isWhitelisted
  const displayedFee = isLeverageEqualToOne ? jupiterPlatformFee : fees
  const total = (stringToNumber(solInput) || 0) + displayedFee
  const minTokenInputValue = isLeverageEqualToOne ? 0 : 0.05

  const initialMarginForCalcSummary = stringToNumber(solInput)
  const baseTokenAmountForCalcSummary = amountFromIx > 0 ? amountFromIx : baseTokenAmount
  const leverageForCalcSummary = Math.max(parseFloat(currentLeverage), 1)

  const borrowedAmountForCalcSummary = BigNumber(coreService.borrowedAmount({ initialMargin: isNaN(initialMarginForCalcSummary) ? 0 : initialMarginForCalcSummary, leverage: leverageForCalcSummary }) || 0)
  // POSSIBLE REGRESSION! DO NOT CHANGE THIS BACK!!!!!!!!!
  const positionSize = borrowedAmountForCalcSummary.plus(initialMarginForCalcSummary).toNumber()
  const entryPrice = baseTokenAmountForCalcSummary ? (initialMarginForCalcSummary * leverageForCalcSummary) / baseTokenAmountForCalcSummary : 0
  const liquidationPrice = coreService.liquidationPrice({
    borrowedAmount: borrowedAmountForCalcSummary,
    interestAccrued: 0,
    collateralValue: baseTokenAmountForCalcSummary || 0,
    liquidationLTV: 90,
  })
  const tradeDetailsGA: TradeDetailsGA = {
    leverage: parseFloat(currentLeverage),
    quoteAmount: stringToNumber(solInput),
    baseAmount: baseTokenAmount,
    token: baseToken?.symbol,
    fee: fees,
    total,
    positionSize,
    dailyInterest: baseToken?.dailyInterest || 0,
    entryPrice,
    liquidationPrice,
  }
  const { borrow } = useBorrow({
    solAmount: stringToNumber(solInput),
    baseTokenAmount,
    toUSDC: priceUsdc,
    baseToken,
    total,
    slippage: parseFloat(slippage),
    leverage: parseFloat(currentLeverage),
    setAmountFromIx,
    tradeDetailsGA,
  })
  const { swap } = useSwap({
    from: quoteToken?.address,
    to: baseToken?.address,
    amount: stringToNumber(solInput),
    slippage: parseFloat(slippage),
    decimalsOfBaseToken: baseToken?.decimals,
    setAmountFromIx,
    tradeDetailsGA,
  })
  const { pools } = usePoolsQuery()

  useEffect(
    () => {
      if (!quoteToken) return
      if (quoteToken.symbol === 'USDC' && isUsdModeOn) {
        setIsUsdModeOn()
      }
    }
    , [quoteToken],
  )
  // TODO: make this better

  useEffect(() => {
    if (!quoteToken || !baseToken) return
    if (!solPriceLoading && priceSol !== undefined) {
      // TODO: make this better

      const marketPriceOfBasedTokenInQuoteCurrency = getUSDCPrice(baseToken?.address).dividedBy(getUSDCPrice(quoteToken?.address))
      const relatedPool = getRelatedPool(baseToken, pools, quoteToken?.address)
      // console.log('relatedPool', relatedPool)
      if (!isWhitelisted || !relatedPool) return
      const maxBorrowLTV = BigNumber.min(relatedPool.maxBorrow.dividedBy(marketPriceOfBasedTokenInQuoteCurrency), BigNumber(0.875))
      // without -1 transaction fails to complete
      const maxLeverageValue = BigNumber(1).dividedBy(BigNumber(1).minus(maxBorrowLTV)).multipliedBy(10).minus(1).dividedBy(10)
      setMaxLeverage(maxLeverageValue.isGreaterThan(1) ? maxLeverageValue.decimalPlaces(1).toNumber() : null)
    }
  }, [solPriceLoading, priceSol, priceUsdc, pools, quoteToken.symbol])

  const isMaxLeverageLoading = maxLeverage === null

  const borrowedAmount = coreService.borrowedAmount({
    initialMargin: isNaN(stringToNumber(solInput)) ? 0 : stringToNumber(solInput) || 0,
    leverage: parseFloat(currentLeverage),
  })

  const availableLiquidity = baseToken?.availableLiquidity || -1

  const localFormatCurrency = useCallback(
    (val: number | null, decimalPointsProps = decimalPoints) => {
      if (val === null) return null
      if (val === undefined || isNaN(val) || !isFinite(val)) return '--'
      return formatCurrency(val, priceUsdc, isUsdModeOn, decimalPointsProps, quoteUnit)
    },
    [priceUsdc, isUsdModeOn, decimalPoints],
  )
  const actionMainButtonMode = getActionMainButtonMode({
    isOnline,
    connected,
    confirming,
    solInput,
    maxAvailableFunds,
    leverage: parseFloat(currentLeverage),
    borrowedAmount,
    availableLiquidity,
    loading,
  })
  const youReceiveWithoutFee = (amountFromIx > 0 ? amountFromIx : baseTokenAmount) || 0
  const displayedYouReceive = formatSol(youReceiveWithoutFee, baseToken?.decimals)

  const quoteUnit = ` ${quoteToken?.symbol?.toLocaleUpperCase()}`
  return (
    <Layout>
      <div
        className={clsxm('flex min-h-[665px] w-full max-w-[460px] flex-col justify-center', {
          'pointer-events-none': amountFromIx > 0,
        })}
      >
        <div className='mb-[10px] flex justify-between'>
          <div className='flex justify-start'>
            <Conditional if={false}>
              <ModeSelectionModal/>
            </Conditional>
          </div>
          <div className='flex justify-end'>
            <Conditional if={!isLeverageEqualToOne && quoteToken.symbol !== 'USDC'}>
              <UsdSwitch className='mr-[10px]' isActive={isUsdModeOn} onClick={setIsUsdModeOn}/>
            </Conditional>
            <SlippageModal/>
          </div>
        </div>
        <section className='bg-main/5 mb-[10px] flex h-[130px] flex-col flex-wrap rounded-lg p-[10px]'>
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='text-main opacity-40'>YOU DEPOSIT</div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint text='This is the initial margin for this position (min: 0.05 SOL required)'/>
              </Conditional>
            </div>
            <div
              className='desk:hover:opacity-70 cursor-pointer transition'
              onClick={() => {
                if (availableFunds && availableFunds >= 0.000001) {
                  // Without 1_000_000 a very small number is displayed as zero
                  setSolInput((Math.floor((availableFunds - 0.05) * 1_000_000) / 1_000_000).toString())
                }
              }}
            >
              <Conditional if={!connecting && connected}>
                <span className='opacity-40'>AVAILABLE FUNDS: </span>
                <LSpan isLoading={availableFunds === null} text={availableFunds === null ? null : formatSol(availableFunds)} textSize={12} unit={quoteUnit}/>
              </Conditional>
            </div>
          </div>
          <div className='my-auto flex items-center justify-between'>
            <div className='flex-none items-center'>
              {/* <Conditional if={tradeMode === 'borrow'}>
                <TokenList list={tradeMode === 'borrow' ? 'base' : 'quote'}/>
              </Conditional> */}
              {/* <Conditional if={tradeMode === 'borrow'}> */}
              {/* <TokenList/> */}
              {/* </Conditional> */}
              {/* <Conditional if={tradeMode !== 'borrow'}> */}
              <Conditional if={IS_MULTI_CURRENCY_ENABLED}>
                <QuoteTokenList/>
              </Conditional>
              <Conditional if={!IS_MULTI_CURRENCY_ENABLED}>
                <div className='flex min-w-[71px] items-center justify-start gap-[5px] rounded-lg py-[3px] pl-[8px]'>
                  <TokenImage altName='SOL' logoURI={SOL_LOGO_URI}/>
                  <span className='text-sm'>SOL</span>
                </div>
              </Conditional>
            </div>
            <div className='flex max-w-[250px] grow-0 items-center overflow-hidden'>
              <NumericTokenInput
                connected={connected}
                MAX_VALUE={maxAvailableFunds}
                MIN_VALUE={minTokenInputValue}
                refresh={refresh}
                setValue={setSolInput}
                size='l'
                value={solInput}
              />
            </div>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!solPriceError && !usdcPriceError && priceUsdc && priceUsdc[SOL_ADDRESS] && quoteToken.symbol !== 'USDC'}>
              <p className='max-w-[200px] overflow-hidden'>
                <LSpan
                  isUnitLeft
                  isLoading={solPriceLoading || usdcPriceLoading}
                  text={formatSol(getUSDCPrice(quoteToken.address).toNumber() * (stringToNumber(solInput)), FIAT_DECIMAL_POINTS)}
                  textSize={12}
                  unit='$'
                />
              </p>
            </Conditional>
            <Conditional if={!!solPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>

        <LeverageField isLoading={isMaxLeverageLoading} isWhitelisted={isWhitelisted} maxLeverage={maxLeverage}/>

        <section
          className={clsxm(
            'my-[10px] flex h-[130px] flex-col justify-between overflow-hidden rounded-lg bg-opacity-10 p-[10px]',
            {
              'bg-alt': isWhitelisted,
            },
            {
              'bg-main': !isWhitelisted,
            },
          )}
        >
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='opacity-40'>
                {tradeMode === 'long' && isLeverageEqualToOne && 'YOU RECEIVE'}
                {tradeMode === 'long' && !isLeverageEqualToOne && 'YOU LONG'}
                {tradeMode === 'short' && 'YOU SHORT'}
                {tradeMode === 'borrow' && 'YOU BORROW'}
              </div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint position='bottom' text='This receivable amount may differ due to slippage.'/>
              </Conditional>
            </div>
            <Conditional if={isWhitelisted}>
              <div>
                <span className='opacity-40'>AVAILABLE LIQUIDITY: </span>
                <LSpan
                  isLoading={isMaxLeverageLoading || solPriceLoading || availableLiquidity === -1}
                  text={formatSol(availableLiquidity)}
                  textSize={12}
                  unit={quoteUnit}
                />
              </div>
            </Conditional>
          </div>
          <div className='flex gap-[10px]'>

            <div className='flex-none items-center'>
              {/* <Conditional if={tradeMode !== 'borrow'}> */}
              <TokenList/>
              {/* </Conditional> */}
              {/* <Conditional if={tradeMode === 'borrow'}>
                <QuoteTokenList/>
              </Conditional> */}
              {/* <TokenList list={tradeMode === 'borrow' ? 'quote' : 'base'}/> */}
            </div>
            <Conditional if={!solPriceError && priceSol && stringToNumber(solInput) >= 0 && leverage !== ''}>
              <div
                className={clsxm(
                  'flex flex-auto items-center justify-end gap-[5px] overflow-hidden text-right',
                  {
                    'text-alt': isWhitelisted,
                  },
                  {
                    'text-main': !isWhitelisted,
                  },
                )}
              >
                <LSpan
                  className='flex-auto truncate text-2xl font-bold'
                  isLoading={!baseToken?.address || (isWhitelisted && isMaxLeverageLoading) || isBaseTokenAmountLoading}
                  text={displayedYouReceive}
                  textSize={24}
                />
              </div>
            </Conditional>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!usdcPriceError && priceUsdc && priceUsdc[baseToken?.address]}>
              <p>
                {`1${quoteUnit} = ${
                  amountFromIx > 0
                    ? formatSol(amountFromIx / Number(solInput) / Number(currentLeverage), CRYPTO_DECIMAL_POINTS)
                    : formatSol(Number(qtPrice) || 0, CRYPTO_DECIMAL_POINTS)
                } ${baseToken?.symbol?.toLocaleUpperCase()}`}
              </p>
            </Conditional>

            <Conditional if={!!usdcPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>
        <section className='mb-[20px] flex items-center justify-between gap-[10px] text-sm'>
          <div className='mb-[-60px] mr-[-120px] mt-[-50px] flex flex-auto flex-col gap-[5px] pb-[60px] pl-[5px] pr-[125px] pt-[50px]'>
            <LabelledValue
              hintInternalComponent={
                <>
                  <Conditional if={hasLavaRockNFT}>
                    <span>Fee reduced for </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> holder!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <Conditional if={!hasLavaRockNFT}>
                    <span>Own a </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> to reduce your fees!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <span>More details on </span>
                  <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
                </>
              }
              hintPosition='right'
              isLoading={isWhitelisted && isMaxLeverageLoading}
              label={`Fee ${isMinFee ? '' : '(0.5%)'}`}
              value={displayedFee === 0 ? 'Free' : localFormatCurrency(displayedFee)}
            />
            <Conditional if={!isLeverageEqualToOne}>
              <LabelledValue
                hintPosition='right'
                isLoading={isWhitelisted && isMaxLeverageLoading}
                label='Total'
                labelClassName='font-bold'
                labelHint={isLeverageEqualToOne ? undefined : 'This payable amount excludes network fees such as rent and gas.'}
                labelHintClassName='tooltip-right'
                value={localFormatCurrency(total)}
                valueClassName={clsxm('font-bold', {
                  'text-yellow': stringToNumber(solInput) > maxAvailableFunds,
                })}
              />
            </Conditional>
          </div>
          <div className='flex-none'>
            <ActionMainButton actionMainButtonMode={actionMainButtonMode} borrow={borrow} loading={loading} swap={swap}/>
          </div>
        </section>
        <LoanSummary
          borrowedAmount={borrowedAmount}
          className={clsxm({ invisible: parseFloat(currentLeverage) === MIN_LEVERAGE || !isWhitelisted })}
          entryPrice={entryPrice}
          hasLavaRockNFT={hasLavaRockNFT}
          isLoading={isMaxLeverageLoading}
          liquidationPrice={liquidationPrice}
          localFormatCurrency={localFormatCurrency}
          positionSize={positionSize - jupiterPlatformFee}
        />
      </div>
    </Layout>
  )
}
