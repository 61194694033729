import { useLavarage } from '@/app/providers/LavarageProvider'
import { web3 } from '@coral-xyz/anchor'
import { type Position } from '@lavarage/entities'
import { getAccount, getAssociatedTokenAddressSync } from '@solana/spl-token'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { JUPITER_PLATFORM_FEE_BPS, SOL_ADDRESS } from '../../config.js'
import { jupiterSource } from '../../services/sources/JupiterSource.js'
import { usePreferencesStore } from '../stores/index.js'
import { useNftAccess } from './useNftAccess.js'

export const fetchSwapInfo = async (lavarage: any, position: Position, isAvoidJupFee: boolean, slippage?: number) => {
  const positionATA = getAssociatedTokenAddressSync(new web3.PublicKey(position.pool.baseCurrency?.address), new web3.PublicKey(position.publicKey), true)
  const balance = await getAccount(lavarage.program.provider.connection, positionATA)
  const info = await jupiterSource.getSwapIx(
    position.pool.baseCurrency?.address,
    SOL_ADDRESS,
    BigNumber(Number(balance.amount)),
    slippage || 0.5,
    lavarage.program.provider.publicKey?.toBase58(),
    undefined,
    isAvoidJupFee,
  )
  return info
}
export function useConfirmedSell(position: Position): number {
  if (position.state === 'closed') return 0
  const [confirmedSellInSol, setConfirmedSellInSol] = useState(0)
  const isPositionCreatedBeforeAddedFee = position.openDate < new Date('2024-09-17') // 17th September 2024
  const { hasLavaRockNFT } = useNftAccess()
  const isAvoidJupFee = isPositionCreatedBeforeAddedFee || hasLavaRockNFT
  const lavarages = useLavarage()
  const [quoteToken] = usePreferencesStore(useShallow(s => [s.quoteToken]))
  // TODO: This is a bug, it should depend on the position's quote token
  const lavarage = quoteToken?.symbol === 'SOL' ? lavarages[0] : lavarages[1]
  const updateConfirmedSellInSol = useCallback(async () => {
    try {
      const info = await fetchSwapInfo(lavarage, position, isAvoidJupFee)
      const sellInSolFromJup = Number(info.quoteResponse.outAmount) / LAMPORTS_PER_SOL
      const jupiterPlatformFee = isAvoidJupFee ? 0 : Number(sellInSolFromJup) * JUPITER_PLATFORM_FEE_BPS / 10_000
      setConfirmedSellInSol(sellInSolFromJup + jupiterPlatformFee)
    }
    catch (error) {
      console.error('Error fetching swap info:', error)
      setConfirmedSellInSol(0)
    }
  }, [isAvoidJupFee, position])

  useEffect(() => {
    updateConfirmedSellInSol()
  }, [updateConfirmedSellInSol])

  return confirmedSellInSol
}
