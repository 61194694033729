import { NotifiCardModal, type NotifiCardModalProps, useNotifiFrontendClientContext, useNotifiHistoryContext } from '@notifi-network/notifi-react'
import '@notifi-network/notifi-react/dist/index.css'
import { useWallet } from '@solana/wallet-adapter-react'
import React, { useEffect, useRef, useState } from 'react'
import { IconNotification } from '../../assets/index.js'
import { Conditional } from './Conditional.js'

export const NotifiElement: React.FC = () => {
  const { wallet, signMessage } = useWallet()
  const adapter = wallet?.adapter
  const publicKey = adapter?.publicKey?.toBase58() ?? null

  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    setIsVisible(prev => !prev)
  }

  const cardRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !cardRef.current?.contains(event.target as Node) &&
        !iconRef.current?.contains(event.target as Node)
      ) {
        setIsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cardRef, iconRef])

  if (publicKey === null || signMessage === undefined) {
    return null
  }

  const { unreadCount } = useNotifiHistoryContext()
  const { frontendClientStatus } = useNotifiFrontendClientContext()

  const copy: NotifiCardModalProps['copy'] = {
    Ftu: {
      FtuTargetEdit: {
        TargetInputs: {
          inputSeparators: {
            email: 'OR',
            sms: 'OR',
            // telegram: 'OR',
            discord: 'OR',
          },
        },
      },
    },
    Inbox: {
      InboxConfigTargetEdit: {
        TargetInputs: {
          inputSeparators: {
            email: 'OR',
            sms: 'OR',
            // telegram: 'OR',
            discord: 'OR',
          },
        },
      },
    },
  }

  return (
    <>
      <div
        ref={iconRef}
        className='relative cursor-pointer'
        onClick={toggleVisibility}
      >
        <IconNotification/>
        <Conditional if={unreadCount > 0}>
          <span className='text-alt absolute -right-2 -top-2 flex size-[14px] items-center justify-center rounded-full border border-white bg-red-500 text-[10px] font-bold'>
            <span className='text-gradient'>{unreadCount}</span>
          </span>
        </Conditional>
      </div>
      <Conditional if={isVisible}>
        <div className='desk:top-[30px] absolute right-0 top-[30px] mt-4'>
          <div ref={cardRef} className='w-[360px]'>
            <div className='notifi-card-modal-container'>
              <NotifiCardModal
                darkMode
                copy={copy}
              />
            </div>
          </div>
        </div>
      </Conditional>
    </>
  )
}
