import { MARKET_PRICE_REFRESH_INTERVAL } from '@/app/app.config'
import { SOL_ADDRESS, USDC_ADDRESS } from '@/config'
import { priceService } from '@/services'
import { web3 } from '@coral-xyz/anchor'
import { type PriceResponse } from '@lavarage/entities'
import { getMint } from '@solana/spl-token'
import { useConnection } from '@solana/wallet-adapter-react'
import { type Connection } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'

const UNSUPPORTED_TOKENS = ['3qj8JbnWypDWkNBvoGkKwAyyHTLBJj8xrCUt1i7ZHds8']
const RATE_LIMIT_PAUSE_INTERVAL = 60000

export const fetchDecimals = async (mintAddress: string, connection: Connection): Promise<number> => {
  try {
    const mintPublicKey = new web3.PublicKey(mintAddress)
    const mintInfo = await getMint(connection, mintPublicKey)
    return mintInfo.decimals
  }
  catch (error) {
    console.error('Failed to fetch mint info:', error)
    throw error
  }
}

const fetchPriceData = async ({
  tokenIds,
  against,
  connection,
}: {
  tokenIds: string[]
  against?: string
  connection: Connection
}): Promise<PriceResponse['data'] | null> => {
  if ((tokenIds.length === 2 && tokenIds[1] === undefined) || (tokenIds.length === 1 && !against)) {
    return null
  }

  try {
    const response = await priceService.getPrice({ tokenIds, secondToken: against })
    if (!response) {
      throw new Error('Failed to fetch price data')
    }
    const tokensNotInResponse = tokenIds.filter(tokenId => !Object.keys(response.data).includes(tokenId))

    if (against !== undefined && tokenIds.length === 1 && tokensNotInResponse.length > 0 && against !== USDC_ADDRESS) {
      if (UNSUPPORTED_TOKENS.includes(against)) {
        return null
      }
      const decimals = await fetchDecimals(against, connection)
      const { data: fallbackResponse } = await axios.get(`https://quote-api.jup.ag/v6/quote?inputMint=${SOL_ADDRESS}&outputMint=${against}&amount=1000000000&slippageBps=70&maxAccounts=26`)
      const pricePerSol = fallbackResponse.outAmount
      return {
        SOL: {
          id: SOL_ADDRESS,
          mintSymbol: 'SOL',
          vsToken: against,
          vsTokenSymbol: '',
          price: pricePerSol / Math.pow(10, decimals),
        },
      }
    }

    // USDC case
    if (tokensNotInResponse.length > 0 && against === USDC_ADDRESS) {
      const missingTokensFallback = {}
      const { data: solUsdcPriceResponse } = await axios.get(`https://api.jup.ag/price/v2?ids=${SOL_ADDRESS}&vsToken=${USDC_ADDRESS}`)
      const solUsdcPrice = solUsdcPriceResponse.data[SOL_ADDRESS].price

      for (const tokenId of tokensNotInResponse) {
        if (UNSUPPORTED_TOKENS.includes(tokenId) || tokenId === undefined) {
          continue
        }

        const tokenDecimals = await fetchDecimals(tokenId, connection)

        const { data: tokenPerSol } = await axios.get(`https://quote-api.jup.ag/v6/quote?inputMint=${SOL_ADDRESS}&outputMint=${tokenId}&amount=1000000000&slippageBps=70&maxAccounts=26`)

        const tokenPricePerSol = tokenPerSol.outAmount / Math.pow(10, tokenDecimals)

        const pricePerTokenSol = 1 / tokenPricePerSol

        const pricePerTokenUsdc = pricePerTokenSol * solUsdcPrice

        missingTokensFallback[tokenId] = {
          id: tokenId,
          vsToken: USDC_ADDRESS,
          vsTokenSymbol: 'USDC',
          price: pricePerTokenUsdc,
        }
      }

      return {
        ...response?.data,
        ...missingTokensFallback,
      }
    }
    return response?.data || null
  }
  catch (error: any) {
    if (error.response && error.response.status === 429) {
      console.error('Received 429 Too Many Requests error. Stopping further fetch attempts.')
      throw new Error('429')
    }
    else if (error instanceof Error) {
      throw error
    }
  }
}

export const useSolPriceQuery = ({ tokenIds, against }: { tokenIds: string[]; against: string }) => {
  const { connection } = useConnection()
  const [refetchInterval, setRefetchInterval] = useState(MARKET_PRICE_REFRESH_INTERVAL)

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['tokenPriceSOL', [...tokenIds, against]],
    queryFn: async () => {
      try {
        return await fetchPriceData({ tokenIds, against, connection })
      }
      catch (error) {
        if (error instanceof Error && error.message === '429') {
          setRefetchInterval(RATE_LIMIT_PAUSE_INTERVAL)
        }
        throw error
      }
    },
    refetchInterval,
    enabled: !!connection,
  })

  useEffect(() => {
    if (!error) {
      setRefetchInterval(MARKET_PRICE_REFRESH_INTERVAL)
    }
  }, [error])

  return { solPrice: data, isLoading, error, refetch }
}

export const useUsdcPriceQuery = ({ tokenIds }: { tokenIds: string[] }) => {
  const { connection } = useConnection()
  const [refetchInterval, setRefetchInterval] = useState(MARKET_PRICE_REFRESH_INTERVAL)

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['tokenPriceUSDC', tokenIds],
    queryFn: async () => {
      try {
        return await fetchPriceData({ tokenIds, against: USDC_ADDRESS, connection })
      }
      catch (error) {
        if (error instanceof Error && error.message === '429') {
          setRefetchInterval(RATE_LIMIT_PAUSE_INTERVAL)
        }
        throw error
      }
    },
    refetchInterval,
    enabled: !!connection,
  })

  useEffect(() => {
    if (!error) {
      setRefetchInterval(MARKET_PRICE_REFRESH_INTERVAL)
    }
  }, [error])

  return { usdcPrice: data, isLoading, error, refetch }
}
