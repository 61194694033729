import { STAKING_PROGRAM_ID } from '@/config'
import { parseJSONString } from '@/utils'
import { type Provider } from '@coral-xyz/anchor'
import { encode } from '@coral-xyz/anchor/dist/cjs/utils/bytes/bs58'
import * as Sentry from '@sentry/react'
import { type WalletContextState } from '@solana/wallet-adapter-react'
import { PublicKey, SystemProgram, Transaction, VersionedTransaction } from '@solana/web3.js'
import { type QueryClient } from '@tanstack/react-query'
import axios, { isAxiosError } from 'axios'

export type ActionType = 'buy' | 'sell' | 'repay' | 'swap' | 'claim' | 'stake' | 'unstake'

type TxDetailsForSentryType = {
  [key: string]: unknown
  type: string
  walletAddress: string
  walletAdapter: string
  tokenSymbol?: string
  tokenAddress?: string
  amount?: number
  leverage?: number
  marginAmount?: number
  total?: number
  slippage?: number
}

type TransactionError = {
  type: string
  message: string
}

type InfoForSentryType = TxDetailsForSentryType & {
  deviceUsed: string
  walletAddress?: string
}

async function confirmTransactionWithCustomPolling(
  provider,
  signature,
  interval = 2000, // 2 seconds
  timeout = 30000, // 30 seconds
): Promise<{ err: TransactionError }> {
  const start = Date.now()

  return new Promise((resolve, reject) => {
    const poll = async () => {
      try {
        const status = await provider.connection.getSignatureStatus(signature)
        const result = status.value

        if (result && result.confirmationStatus === 'finalized') {
          resolve(result)
        }
        else if (Date.now() - start > timeout) {
          reject(new Error('Transaction confirmation timeout'))
        }
        else {
          setTimeout(poll, interval)
        }
      }
      catch (error) {
        reject(error)
      }
    }

    poll()
  })
}

export async function sendTransaction(
  tx: VersionedTransaction,
  addAlert,
  removeAlert,
  setConfirming,
  provider: Provider,
  wallet: WalletContextState,
  txDetailsForSentry?: TxDetailsForSentryType,
  actionType?: ActionType,
  queryClient?: QueryClient,
): Promise<string | undefined> {
  const latestBlockHash = await provider.connection.getLatestBlockhash()
  const tx2 = VersionedTransaction.deserialize(tx.serialize())
  tx2.message.recentBlockhash = latestBlockHash.blockhash
  const transactionSimulation = await provider.connection.simulateTransaction(tx, {
    replaceRecentBlockhash: true,
  })
  const transactionLogs = transactionSimulation.value.logs
  console.log('transactionLogs', transactionLogs)

  const tipTx = new Transaction().add(SystemProgram.transfer({
    toPubkey: new PublicKey('96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5'),
    fromPubkey: provider.publicKey,
    lamports: 2000000,
  }))
  tipTx.recentBlockhash = latestBlockHash.blockhash
  tipTx.feePayer = provider.publicKey
  const signedTx = await wallet.signAllTransactions?.([tx, tipTx])
  const signature = encode(signedTx?.[0].signatures[0] as Uint8Array)
  const userDevice = navigator.userAgent

  function getSentryEventId(err: string, extraInfo: Record<string, unknown>) {
    return Sentry.captureException(err, {
      extra: {
        info: JSON.stringify(extraInfo),
      },
    })
  }

  const infoForSentry = {
    walletAddress: provider.publicKey?.toBase58(),
    deviceUsed: userDevice,
    ...txDetailsForSentry,
  }

  if (transactionLogs === null || transactionLogs.length === 0) {
    const eventId = getSentryEventId('Transaction simulation failed', infoForSentry)
    handleError(
      new Error('Transaction simulation failed'),
      signature,
      eventId,
      infoForSentry,
      actionType,
      addAlert,
    )
    throw new Error('Transaction simulation failed')
  }

  for (const log of transactionLogs) {
    if (log.includes('failed') || log.includes('panicked') || log.includes('error')) {
      const eventId = getSentryEventId(log, infoForSentry)
      handleError(new Error(parseJSONString(JSON.stringify(log))), signature, eventId, infoForSentry, actionType, STAKING_PROGRAM_ID, addAlert, log)
      throw new Error(parseJSONString(JSON.stringify(log)))
    }
  }

  try {
    const sendBundle = async () => {
      await axios.post('https://mainnet.block-engine.jito.wtf/api/v1/bundles', {
        jsonrpc: '2.0',
        id: 1,
        method: 'sendBundle',
        params: [[encode(signedTx[0]?.serialize()), encode(signedTx[1]?.serialize())]],
      })
      await provider.connection.sendRawTransaction(signedTx[0].serialize(), {
        skipPreflight: true,
      })
    }

    const RETRIES = 3

    for (let attempt = 1; attempt <= RETRIES; attempt++) {
      try {
        await sendBundle()
      }
      catch (e) {
        if (attempt < RETRIES) {
          console.error(`Attempt ${attempt} failed. Retrying...`)
          setTimeout(() => {}, 1000)
          continue
        }
        console.error(e)
      }
    }

    addAlert({
      type: 'inProgress',
      tx: signature,
    })

    const confirmation = await confirmTransactionWithCustomPolling(
      provider,
      signature,
      5000, // Poll every 5 seconds
      30000, // Timeout after 30 seconds
    )

    removeAlert(0)
    setConfirming(false)

    if (queryClient) {
      queryClient.invalidateQueries({ queryKey: ['activePositions'] })
    }

    if (confirmation && confirmation.error) {
      const eventId = getSentryEventId(confirmation.error, infoForSentry)
      addAlert({
        type: 'transactionFails',
        reasonMessage: '',
        tx: signature,
        sentryId: eventId,
      })
      throw new Error(parseJSONString(JSON.stringify(confirmation.error)))
    }
    else {
      addAlert({ type: 'success', tx: signature })
    }
    return signature
  }
  catch (error) {
    removeAlert(0)
    setConfirming(false)

    if (error instanceof Error) {
      if (isAxiosError(error) && error.response) {
        error.message = `Server responded with status code ${error.response.status}: ${error.response.data}`
      }
      else if (isAxiosError(error) && error.request) {
        // network error case
        error.message = 'A network error occurred, preventing the transaction from being processed. Please check your connection and try again.'
      }

      const sentryEventId = getSentryEventId(error, infoForSentry)
      handleError(error, signature, sentryEventId, infoForSentry, actionType, addAlert)
      throw error
    }
  }
}

// @TODO: refactor this out of the sendTransaction function
// to another layer that handles the error messages
function handleError(
  error: Error,
  signature: string,
  sentryEventId: string,
  infoForSentry: InfoForSentryType,
  actionType: ActionType | undefined,
  addAlert: (alert: { type: string; reasonMessage: string; tx?: string; sentryId?: string }) => void,
) {
  // first one - Raydium
  // second one - Orca
  // third one - our Staking program
  // fourth one - Jupiter program
  if (error.message.includes('Program CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK') ||
    error.message.includes('Program whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc') ||
    error.message.includes(`Program ${STAKING_PROGRAM_ID}`) ||
    error.message.includes('Program JUP')) {
    addAlert({
      type: 'transactionFails',
      reasonMessage: 'An unexpected error occurred, please try again later.',
      sentryId: sentryEventId,
    })
    return
  }

  if (error.message.includes('Transaction confirmation timeout')) {
    addAlert({
      type: 'transactionWaiting',
      tx: signature,
      reasonMessage: 'Transaction confirmation timeout. Please verify the transaction status in the blockchain.',
    })
    return
  }

  if (error.message.includes('Transaction simulation failed') || error.message.includes('failed to simulate transaction')) {
    addAlert({
      type: 'transactionFails',
      reasonMessage: 'An error occurred while opening a position. Please try again.',
      sentryId: sentryEventId,
    })
    return
  }

  if (error.message.includes('InstructionError') || error.message.includes('Custom:6001')) {
    addAlert({
      type: 'transactionFails',
      reasonMessage: 'An error occurred while opening a position. Please try again.',
      sentryId: sentryEventId,
    })
    Sentry.captureException(error, {
      extra: { transactionLogs: JSON.stringify(infoForSentry) },
    })
    return
  }

  if (error.message.includes('User rejected the request')) {
    return
  }

  if (error.message.includes('Network Error')) {
    addAlert({
      type: 'transactionFails',
      reasonMessage: 'A network error occurred, preventing the transaction from being processed. Please check your connection and try again.',
    })
    return
  }

  if (error.message.includes('Request failed with status code 400') && actionType === 'sell') {
    addAlert({
      type: 'transactionFails',
      reasonMessage: 'Failed to close the position, please reach out to us on Discord for assistance',
    })
    return
  }

  else {
    addAlert({
      type: 'transactionFails',
      reasonMessage: error.message,
      tx: signature,
      sentryId: sentryEventId,
    })
    return
  }
}
