import { TokenImage } from '@/app/components'

type TokenDescriptor = {
  logoURI: string
  altName?: string
}

type TokenPairProps = {
  left: TokenDescriptor
  right: TokenDescriptor
}
export const TokenPair: React.FC<TokenPairProps> = ({ left, right }: TokenPairProps) => {
  return (
    <>
      <div className='flex'>
        <div className='-mr-[14px]'>
          <TokenImage altName={left.altName || 'from token image'} logoURI={left.logoURI}/>
        </div>
        <TokenImage altName={right.altName || 'to token image'} logoURI={right.logoURI}/>
      </div>
    </>
  )
}
