import { CoinButton, Conditional, TokenImage } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { clsxm } from '@/app/helpers/clsxm'
import { usePreferencesStore } from '@/app/stores'
import { DEFAULT_TOKENS } from '@/config.js'
import { type TokenInfo } from '@/services'
import React, { type CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import { useShallow } from 'zustand/react/shallow'

export const QuoteTokenList: React.FC = memo(() => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [quoteToken, setQuoteToken, baseToken, setBaseToken] = usePreferencesStore(useShallow(s => [s.quoteToken, s.setQuoteToken, s.baseToken, s.setBaseToken]))
  // const { pools } = usePoolsQuery()
  const defaultTokens = [DEFAULT_TOKENS.quote, DEFAULT_TOKENS.base] as TokenInfo[]
  // const { refetchWithToken: updateTokenLiquidity } = useTokenLiquidity()
  const tokens = defaultTokens

  const handleTokenClick = useCallback(
    (selectedToken: TokenInfo) => {
      // updateTokenLiquidity(selectedToken)

      if (baseToken?.address === selectedToken.address && quoteToken) {
        // updateTokenLiquidity(quoteToken)
        setBaseToken(quoteToken)
      }
      setQuoteToken(selectedToken)
      // selectedToken.whitelisted = true
      // fetchTokens()

      setIsModalVisible(false)
    },
    [setBaseToken, setQuoteToken, baseToken, quoteToken],
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsModalVisible(false)
      }
    },
    [isModalVisible],
  )

  const toggleModal = () => {
    setIsModalVisible(prev => !prev)
  }

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener, true)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener, true)
    }
  }, [isModalVisible, handleClickOutside])

  const prettyAddress = (publicKey: string) => {
    return `${publicKey.slice(0, 4)}..${publicKey.slice(-3)}`
  }

  const Row = memo(({ index, style }: { index: number; style: CSSProperties }) => {
    const token = tokens[index]
    return (
      <div key={`token${index}`} className='px-[10px] last:pb-[64px]' style={style}>
        <div
          className={clsxm(
            'desk:hover:bg-opacity-20 mt-[10px] flex h-[44px] w-full cursor-pointer items-center justify-between rounded-lg bg-opacity-10 pt-[-10px] text-base transition',
            {
              // 'bg-alt text-alt': token.whitelisted,
              'bg-main text-main': true,
            },
          )}
          style={{ cursor: 'pointer' }}
          onClick={() => handleTokenClick(token)}
        >
          <div className='flex min-w-0 flex-1 items-center px-2'>
            <TokenImage altName={token.name} logoURI={token.logoURI} tag={undefined}/>
            <span className='min-w-0 truncate px-[5px]'>{token.name}</span>
            <span
              className='bg-main text-main desk:hover:bg-opacity-10 flex h-[24px] max-w-[220px] cursor-pointer items-center rounded-xl bg-opacity-5 px-[10px] text-center text-xs'
              title={token.address.toLowerCase()}
              onClick={() => window.open(`https://birdeye.so/token/${token.address}?chain=solana`, '_blank', 'noopener,noreferrer')}
            >
              {prettyAddress(token.address.toLowerCase())}
            </span>
          </div>
          <span className='shrink-0 px-2 uppercase'>{token.symbol}</span>
        </div>
      </div>
    )
  })

  Row.displayName = 'TokenList:Row'

  return (
    <div className='w-full'>
      <CoinButton className='cursor-pointer' loading={!quoteToken?.address} token={quoteToken} onClick={toggleModal}/>
      <Conditional if={isModalVisible}>
        <Modal>
          <div ref={wrapperRef} className='bg-vibrant modal-box  max-w-[380px] overflow-x-hidden px-0 py-[10px] text-xs'>
            <div className='w-full bg-transparent'>
              <Conditional if={tokens.length}>
                <List className='hide-scrollbar' height={130} itemCount={tokens.length} itemSize={50} width='auto'>
                  {Row}
                </List>
              </Conditional>
            </div>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
})
