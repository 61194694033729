import { type TokenInfo } from '@/services/types'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { DEFAULT_TOKENS } from '../../config.js'
import { type UserBalance } from '../hooks/useBalance.js'

export interface IPreferencesState {
  solInput: string
  stakingInput: string
  unstakingInput: string
  leverage: string | null
  defaultLeverage: string | null
  slippage: string
  baseToken: TokenInfo
  quoteToken: TokenInfo
  userBalance: UserBalance | null
  isUsdModeOn: { trading: boolean; positions: boolean; staking: boolean }
  hasLavaRockNFT: boolean
  isCheckingNFT: boolean
  tradeMode: 'long' | 'short' | 'borrow'
}

interface PreferencesStoreState extends IPreferencesState {
  setSolInput: (solInput: string) => void
  setStakingInput: (stakingInput: string) => void
  setUnstakingInput: (unstakingInput: string) => void
  setLeverage: (leverage: string | null) => void
  setDefaultLeverage: (defaultLeverage: string | null) => void
  setSlippage: (slippage: string) => void
  setBaseToken: (token: TokenInfo) => void
  setQuoteToken: (token: TokenInfo) => void
  setUserBalance: (userBalance: UserBalance | null) => void
  setIsUsdModeOn: (isUsdModeOn: { trading: boolean; positions: boolean; staking: boolean }) => void
  setHasLavaRockNFT: (hasLavaRockNFT: boolean) => void
  setIsCheckingNFT: (isCheckingNFT: boolean) => void
  setTradeMode: (tradeMode: 'long' | 'short') => void
}

export const usePreferencesStore = create<PreferencesStoreState>()(devtools(persist(
  set => ({
    userBalance: null,
    solInput: '1',
    stakingInput: '1',
    unstakingInput: '1',
    leverage: null,
    defaultLeverage: null,
    baseToken: DEFAULT_TOKENS.base,
    quoteToken: DEFAULT_TOKENS.quote,
    slippage: '0.5',
    isUsdModeOn: { trading: false, positions: false, staking: false },
    hasLavaRockNFT: false,
    isCheckingNFT: false,
    tradeMode: 'long',

    setUserBalance: userBalance => set({ userBalance }),
    setSolInput: solInput => set({ solInput }),
    setStakingInput: stakingInput => set({ stakingInput }),
    setUnstakingInput: unstakingInput => set({ unstakingInput }),
    setLeverage: leverage => set({ leverage }),
    setDefaultLeverage: defaultLeverage => set({ defaultLeverage }),
    setBaseToken: baseToken => set({ baseToken }),
    setQuoteToken: quoteToken => set({ quoteToken }),
    setSlippage: slippage => set({ slippage }),
    setIsUsdModeOn: isUsdModeOn => set({ isUsdModeOn }),
    setHasLavaRockNFT: hasLavaRockNFT => set({ hasLavaRockNFT }),
    setIsCheckingNFT: isCheckingNFT => set({ isCheckingNFT }),
    setTradeMode: tradeMode => set({ tradeMode }),
  }),
  { name: 'user-storage' },
)))
