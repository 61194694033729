import { type TokenInfo } from '@/services'
import { type Pool } from '@lavarage/entities'

export const getRelatedPool = (token: TokenInfo, pools: Pool[], quoteCurrency?: string) => {
  const relatedPools = pools.filter(pool => pool.baseCurrency.address === token?.address && pool.maxBorrow.gt(0) && (pool.nodeWallet.mint ? pool.nodeWallet.mint === quoteCurrency : true))
  // console.log('relatedPoolsNow', pools.filter(pool => pool.maxBorrow.gt(0) && (pool.nodeWallet.mint ? pool.nodeWallet.mint === quoteCurrency : quoteCurrency === 'So11111111111111111111111111111111111111112')))
  const relatedPoolWithBiggestLiquidity = relatedPools.sort((a, b) => {
    return b.nodeWallet.totalFunds?.minus(a.nodeWallet.totalFunds).toNumber()
  })[0]

  return relatedPoolWithBiggestLiquidity
}
