import { trackStakeCancelledGA, trackStakeExecuteGA, trackStakeFailedGA, trackStakeSuccessGA, trackUnstakeCancelledGA, trackUnstakeExecuteGA, trackUnstakeFailedGA, trackUnstakeSuccessGA } from '@/app/analytics'
import { Conditional, LSpan, NumericTokenInput, TokenImage, UsdSwitch } from '@/app/components'
import { getTextColorByString } from '@/app/helpers/getTextColorByString'
import { sendTransaction } from '@/app/helpers/sendTransaction'
import { useVaultBalanceQuery } from '@/app/hooks/queries'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { useStakeAccess } from '@/app/hooks/useStakeAccess'
import { useStendingService } from '@/app/providers/StendingProvider'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { CRYPTO_DECIMAL_POINTS, FIAT_DECIMAL_POINTS, LSTSOL_LOGO_URI, MIN_PLATFORM_FEE_IN_SOL, SOL_ADDRESS, SOL_LOGO_URI } from '@/config'
import { formatPercentWithoutPlus, formatSol } from '@/utils/formatters'
import { stringToNumber } from '@/utils/stringToNumber'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { Hint } from '../../../components/Hint.js'
import { usePriceContext } from '../../../providers/PriceProvider.js'
import { getActionStakeButtonMode } from '../utils/getActionStakeButtonMode'
import { VaultUtils } from '../utils/VaultUtils'
import { ActionStakeButton } from './ActionStakeButton'
import { ProgressComponent } from './ProgressComponent'

type WidgetTokenInfo = {
  symbol: string
  imageUri: string
}

type StakeUnstakeWidgetProps = {
  mode: 'stake' | 'unstake'
}

export function StakeUnstakeWidget({ mode }: StakeUnstakeWidgetProps) {
  const { userBalance, connected, connecting } = useBalance()
  const availableSol = userBalance?.SOL
  const stendingService = useStendingService()
  if (!stendingService) return null
  const wallet = useWallet()
  const { vaultBalance, fetchVaultBalance, isLoading } = useVaultBalanceQuery()
  const [lstSolAmount, setLstSolAmount] = useState<number>(0)
  const { setVisible } = useWalletModal()
  const [pnl, setPnL] = useState<number>(0)
  const [pnlLastUpdate, setPnLLastUpdate] = useState<number>(0)
  const [pnlHist, setPnLHist] = useState<number>()
  const { hasAccessToStake } = useStakeAccess()
  const [stakingInput, setStakingInput, unstakingInput, setUnstakingInput, isUsdModeOn, setIsUsdModeOn] = usePreferencesStore(useShallow(s => [
    s.stakingInput,
    s.setStakingInput,
    s.unstakingInput,
    s.setUnstakingInput,
    s.isUsdModeOn.staking,
    () => s.setIsUsdModeOn({ ...s.isUsdModeOn, staking: !s.isUsdModeOn.staking }),
  ]))

  const [addAlert, removeAlert, setConfirming, confirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.setConfirming, state.confirming]))

  const { priceUsdc, usdcPriceLoading, usdcPriceError, refetch } = usePriceContext()

  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    stendingService?.getUserLstSolBalance().then(res => {
      setLstSolAmount(res / 1_000_000_000)
    })

    stendingService?.getCurrentPnl().then(res => {
      setPnL(res.pnl)
      setPnLLastUpdate(res.lastUpdate)
      setPnLHist(res.hist)
    })
  }, [stendingService, vaultBalance])

  const tokens: WidgetTokenInfo[] = [
    {
      symbol: 'SOL',
      imageUri: SOL_LOGO_URI,
    },
    {
      symbol: 'LSTSOL',
      imageUri: LSTSOL_LOGO_URI,
    },
  ]

  const maxAvailableSol = availableSol ? availableSol - MIN_PLATFORM_FEE_IN_SOL : 0
  const maxLstSolAmount = lstSolAmount ? (Math.floor(lstSolAmount * 10_000) / 10_000) : 0

  const tokenFrom: WidgetTokenInfo = mode === 'stake' ? tokens[0] : tokens[1]
  const tokenTo: WidgetTokenInfo = mode === 'stake' ? tokens[1] : tokens[0]

  const decimalPoints = isUsdModeOn ? FIAT_DECIMAL_POINTS : CRYPTO_DECIMAL_POINTS

  const localFormatCurrency = useCallback(
    (val: number) => {
      if (typeof priceUsdc === 'undefined' || priceUsdc === null) {
        return '--'
      }
      else return VaultUtils.localFormatCurrency(val, priceUsdc, isUsdModeOn, decimalPoints)
    },
    [priceUsdc, isUsdModeOn, decimalPoints],
  )
  const isOnline = useNetworkStatus()

  const actionButtonMode = getActionStakeButtonMode({
    isOnline,
    mode,
    hasAccess: hasAccessToStake,
    confirming,
    amount: mode === 'stake' ? stakingInput : unstakingInput,
    available: mode === 'stake' ? maxAvailableSol : maxLstSolAmount,
    connected: wallet.connected,
  })

  const stake = async () => {
    try {
      setConfirming(true)
      trackStakeExecuteGA()
      const tx = await stendingService?.stake(stringToNumber(stakingInput))
      const txDetailsForSentry = {
        type: 'Stake',
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        amount: stringToNumber(stakingInput),
      }
      const result = await sendTransaction(tx, addAlert, removeAlert, setConfirming, stendingService?.program.provider, wallet, txDetailsForSentry)
      fetchVaultBalance()
      const detailsForGA = {
        solAmount: stakingInput,
        lstsolReceived: VaultUtils.calculateReceiveAmount('stake', stakingInput, vaultBalance?.nav),
      }
      if (result) {
        trackStakeSuccessGA(detailsForGA)
      }
    }
    catch (error) {
      if (error instanceof Error && error.message.includes('User rejected the request')) {
        trackStakeCancelledGA()
        console.error('User rejected the stake request', error)
      }
      else {
        trackStakeFailedGA()
        console.error('Failed to stake', error)
        addAlert({
          type: 'error',
          reasonMessage: 'Failed to stake. Please try again later.',
        })
      }
    }
    finally {
      setConfirming(false)
    }
  }

  const unstake = async () => {
    try {
      setConfirming(true)
      trackUnstakeExecuteGA()
      const [tx, unstakeAccount] = await stendingService.unstake(stringToNumber(unstakingInput))
      const txDetailsForSentry = {
        type: 'Unstake',
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        amount: stringToNumber(unstakingInput),
      }
      const result = await sendTransaction(tx, addAlert, removeAlert, setConfirming, stendingService.program.provider, wallet, txDetailsForSentry)
      if (result) {
        for (;;) {
          const ac = await stendingService.program.account.unstakeAccount.fetch(unstakeAccount)
          if (ac.depositor.equals(stendingService.program.provider.publicKey)) break
          await new Promise(res => {
            setTimeout(res, 2000)
          })
        }
        const detailsForGA = {
          lstsolAmount: unstakingInput,
          solReceived: VaultUtils.calculateReceiveAmount('unstake', unstakingInput, vaultBalance?.nav),
        }
        trackUnstakeSuccessGA(detailsForGA)
        setConfirming(false)
        await stendingService.getUnstakeAccounts(true)
        navigate({ pathname: '/stake/claim', search: location.search })
      }
    }
    catch (error) {
      if (error instanceof Error && error.message.includes('User rejected the request')) {
        trackUnstakeCancelledGA()
        console.error('User rejected the unstake request', error)
      }
      else {
        trackUnstakeFailedGA()
        console.error('Failed to unstake', error)
        addAlert({
          type: 'transactionFails',
          reasonMessage: 'Failed to unstake. Please try again later.',
        })
      }
    }
    finally {
      setConfirming(false)
    }
  }

  const historicalPnL = pnlHist && (vaultBalance?.nav - 1) * 100
  const delegatedAPY = parseFloat('7')
  // TODO: remove deployedAPY if not needed anymore and refactor the code
  // const deployedAPY = parseFloat(VaultUtils.calculateApy(pnlHist, pnl, vaultBalance?.deployed.toNumber(), pnlLastUpdate))
  const combinedAPY = (historicalPnL / (Date.now() / 1000 - 1716350663)) * 31536000
  const delegatedValue = parseFloat(vaultBalance?.delegated.dividedBy(vaultBalance.total).multipliedBy(100).toFixed(1))
  const deployedValue = parseFloat(vaultBalance?.deployed.dividedBy(vaultBalance.total).multipliedBy(100).toFixed(1))
  return (
    <>
      <section className='flex flex-col items-center justify-center text-center'>
        <section className='bg-main/5 mb-[10px] flex w-full flex-col rounded-lg px-[10px] pb-[20px] pt-[10px]'>
          <div className='mb-[10px] flex justify-between'>
            <div className='text-main text-xs opacity-40'>VAULT</div>
            <UsdSwitch isActive={isUsdModeOn} onClick={setIsUsdModeOn}/>
          </div>
          <div className='mb-[10px] flex items-end justify-center gap-[5px] font-bold'>
            <LSpan
              className='text-[30px] leading-[36px]'
              isLoading={isLoading || usdcPriceLoading}
              text={
                isUsdModeOn
                  ? localFormatCurrency(vaultBalance?.total?.toNumber()).split('.')[0]
                  : localFormatCurrency(vaultBalance?.total?.toNumber()) === '--'
                    ? '--'
                    : localFormatCurrency(vaultBalance?.total?.decimalPlaces(0).toNumber()).slice(0, -4)
              }
              textSize={36}
            />
            {!isUsdModeOn && !isLoading && <span className='mb-1 text-sm leading-[17px]'>SOL</span>}
          </div>
          <div className='mb-[20px] flex items-center justify-center gap-[5px]'>
            <LSpan
              className={getTextColorByString(formatPercentWithoutPlus(combinedAPY, 1))}
              isLoading={historicalPnL === undefined}
              text={`${formatPercentWithoutPlus(combinedAPY, 1)} APY`}
              textSize={24}
            />
            <Hint position='left' text='APY since inception.'/>
          </div>
          <div className='flex items-center justify-center gap-[20px]'>
            <Conditional if={!isLoading}>
              <ProgressComponent delegatedValue={delegatedValue} deployedValue={deployedValue}/>
            </Conditional>
            <Conditional if={isLoading}>
              <div className='loading loading-spinner  w-[130px] '/>
            </Conditional>
            <ul className='flex flex-col gap-[14px] text-xs'>
              <li className='flex flex-col items-start gap-[8px]'>
                <div className='flex items-center gap-[5px]'>
                  <div className='bg-purple size-[14px] rounded-full'/>
                  <span className=''>Delegated</span>
                  <Hint position='left' text='Funds from the vault delegated to external validators.'/>
                </div>

                <div className='flex items-center gap-[5px] pl-[19px]'>
                  <LSpan isLoading={isLoading} text={localFormatCurrency(vaultBalance?.delegated.toNumber()).split('.')[0]} textSize={12}/>
                  <span className={getTextColorByString(formatPercentWithoutPlus(delegatedAPY, 1))}>{`≈${formatPercentWithoutPlus(delegatedAPY, 1)} APY`}</span>
                </div>
              </li>
              <li className='flex flex-col items-start gap-[8px]'>
                <div className='flex items-center gap-[5px]'>
                  <div className='bg-alt size-[14px] rounded-full'/>
                  <span className=''>Deployed</span>
                  <Hint position='left' text='Funds from the vault deployed to loan pools.'/>
                </div>
                <div className='flex items-center gap-[5px] pl-[19px]'>
                  <LSpan isLoading={isLoading} text={localFormatCurrency(vaultBalance?.deployed.toNumber()).split('.')[0]} textSize={12}/>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className='bg-main mb-[10px] flex h-[130px] w-full flex-col flex-wrap justify-between rounded-lg bg-opacity-5 p-[10px]'>
          <div className='flex justify-between text-xs'>
            <div className='flex items-center justify-start gap-[5px] text-xs'>
              <div className='text-main opacity-40'>
                YOU
                {mode === 'stake' ? ' STAKE' : ' UNSTAKE'}
              </div>
              <Conditional if={mode === 'stake'}>
                <Hint text='Stake amount (min: 0.05 SOL)' width={250}/>
              </Conditional>
            </div>
            <div
              className='desk:hover:opacity-70 cursor-pointer'
              onClick={() => {
                if (availableSol && availableSol >= 0.000001) {
                  switch (mode) {
                    case 'stake':
                      setStakingInput((Math.floor(availableSol * 1_000_000) / 1_000_000 - 0.005).toString())
                      break
                    case 'unstake':
                      setUnstakingInput(maxLstSolAmount.toString())
                      break
                    default:
                      break
                  }
                }
              }}
            >
              <Conditional if={!connecting && connected}>
                <span className='opacity-40'>AVAILABLE FUNDS: </span>
                <span>
                  <Conditional if={mode === 'stake'}>
                    {availableSol && formatSol(Math.floor(availableSol * 1_000_000) / 1_000_000)}
                    {' '}
                    {tokenFrom.symbol}
                  </Conditional>
                  <Conditional if={mode === 'unstake'}>
                    {formatSol(maxLstSolAmount)}
                    {' '}
                    {tokenFrom.symbol}
                  </Conditional>
                </span>
              </Conditional>
            </div>
          </div>
          <div className='my-auto flex h-[44px] items-center justify-between'>
            <div className='flex min-w-[71px] items-center justify-start gap-[5px] rounded-lg py-[3px] pl-[8px]'>
              <TokenImage altName={tokenFrom.symbol} logoURI={tokenFrom.imageUri}/>
              <span className='text-sm'>{tokenFrom.symbol}</span>
            </div>
            <div className='flex max-w-[250px] grow-0 items-center overflow-hidden'>
              <NumericTokenInput
                connected={connected}
                MAX_VALUE={mode === 'stake' ? maxAvailableSol : maxLstSolAmount}
                MIN_VALUE={mode === 'stake' ? 0.05 : 0}
                refresh={refetch}
                setValue={mode === 'stake' ? setStakingInput : setUnstakingInput}
                size='l'
                value={mode === 'stake' ? stakingInput : unstakingInput}
              />
            </div>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!usdcPriceError && priceUsdc && priceUsdc[SOL_ADDRESS]}>
              <p className='max-w-[200px] overflow-hidden'>
                $
                {formatSol((priceUsdc?.[SOL_ADDRESS]?.price || 0) * (mode === 'stake' ? stringToNumber(stakingInput) : stringToNumber(unstakingInput) * vaultBalance?.nav || 0), FIAT_DECIMAL_POINTS)}
              </p>
            </Conditional>

            <Conditional if={!!usdcPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>
        <section className='bg-alt mb-[10px] flex h-[130px] w-full flex-col flex-wrap rounded-lg bg-opacity-10 p-[10px]'>
          <div className='flex h-[15px] justify-between text-xs'>
            <div className='text-main opacity-40'>YOU RECEIVE</div>
          </div>
          <div className='my-auto flex h-[44px] items-center justify-between'>
            <div className='flex min-w-[71px] items-center justify-start gap-[5px] rounded-lg py-[3px] pl-[8px]'>
              <TokenImage altName={tokenTo.symbol} logoURI={tokenTo.imageUri}/>
              <span className='text-alt text-sm'>{tokenTo.symbol}</span>
            </div>
            <div className='flex max-w-[250px] grow-0 items-center overflow-hidden'>
              <LSpan
                className='text-alt text-[24px] font-bold'
                isLoading={isLoading}
                text={VaultUtils.calculateReceiveAmount(mode, mode === 'stake' ? stakingInput : unstakingInput, vaultBalance?.nav) === ''
                  ? ''
                  : formatSol(parseFloat(VaultUtils.calculateReceiveAmount(mode, mode === 'stake' ? stakingInput : unstakingInput, vaultBalance?.nav)))}
                textSize={12}
              />
            </div>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!usdcPriceError && priceUsdc && priceUsdc[SOL_ADDRESS]}>
              <p className='max-w-[200px] overflow-hidden text-xs'>
                <Conditional if={mode === 'stake'}>
                  {`1 ${tokenFrom.symbol} = ${VaultUtils.calculateExchangeRate(mode, vaultBalance?.nav)} ${tokenTo.symbol}`}
                </Conditional>
                <Conditional if={mode === 'unstake'}>
                  {`1 ${tokenFrom.symbol} = ${VaultUtils.calculateExchangeRate(mode, vaultBalance?.nav)} ${tokenTo.symbol}`}
                </Conditional>
              </p>
            </Conditional>

            <Conditional if={!!usdcPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>

        <section className='flex w-full items-center justify-end'>
          <ActionStakeButton actionButtonMode={actionButtonMode} connect={() => setVisible(true)} stake={stake} unstake={unstake}/>
        </section>
      </section>
    </>
  )
}
