import { Conditional, Tip } from '@/app/components'
import { useUnstakeAccountsQuery, useVaultBalanceQuery } from '@/app/hooks/queries'
import { Layout } from '@/app/pages/layout/Layout'
import { VaultBalanceProvider } from '@/app/pages/stake/VaultBalanceProvider'
import { StakeTabControl } from '@/app/pages/stake/components'
import { Outlet, useLocation } from 'react-router-dom'

export function StakePage() {
  const location = useLocation()
  const { unstakeAccounts, isLoading: isUnstakeAccountsLoading } = useUnstakeAccountsQuery()
  const { isLoading: isVaultBalanceLoading, fetchVaultBalance } = useVaultBalanceQuery()

  const isLoading = isUnstakeAccountsLoading || isVaultBalanceLoading

  return (
    <Layout className='justify-start'>
      <div className='mb-10 flex w-full max-w-[460px] flex-1 flex-col gap-5'>
        <StakeTabControl quantityOfClaim={unstakeAccounts?.length ?? 0}/>
        <Tip>
          <Conditional if={location.pathname === '/stake'}>
            <span>
              {'Stake your SOL to receive lstSOL (Lavarage Staked SOL). Your staked amount will be actively utilized in our vault. '}
              <a className='text-alt border-b hover:border-b-0' href='https://lavarage.gitbook.io/lavarage/staking' rel='noreferrer' target='_blank'>
                <span>Learn more</span>
              </a>
              .
            </span>
          </Conditional>
          <Conditional if={location.pathname === '/stake/unstake'}>
            <span>
              {'Unstake your lstSOL (Lavarage Staked SOL) to receive SOL. It may take up to 5 days before you can claim your funds. '}
              <a className='text-alt border-b hover:border-b-0' href='https://lavarage.gitbook.io/lavarage/staking' rel='noreferrer' target='_blank'>
                <span>Learn more</span>
              </a>
              .
            </span>
          </Conditional>

          <Conditional if={location.pathname === '/stake/claim'}>
            <span>
              {'Unstaking may take up to 5 days to complete. '}
              <a className='text-alt border-b hover:border-b-0' href='https://lavarage.gitbook.io/lavarage/staking' rel='noreferrer' target='_blank'>
                <span>Learn more</span>
              </a>
              .
            </span>
          </Conditional>
        </Tip>
        <VaultBalanceProvider
          value={{
            refreshVaultBalance: fetchVaultBalance,
          }}
        >
          <Outlet/>
        </VaultBalanceProvider>
      </div>
    </Layout>
  )
}
