import { type TokenInfo } from '../../../../services/types.js'

export const checkWhitelisted = (token: TokenInfo, quoteSymbol: string) => {
  if (quoteSymbol === 'SOL') {
    return !!token.whitelisted
  }
  if (quoteSymbol === 'USDC') {
    return !!token.whitelistedForUSDC
  }
  return false
}
