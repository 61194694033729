import { useLavarage } from '@/app/providers/LavarageProvider'
import { usePreferencesStore } from '@/app/stores'
import { formatLamportsToSol } from '@/utils'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { USDC_ADDRESS } from '../../config.js'

export type UserBalance = {
  SOL: number | null
  USDC: number | null
}

type Balance = {
  userBalance: UserBalance | null
  connected: boolean
  connecting: boolean
  publicKey: PublicKey | null
}

const activeSubscriptions: Record<string, number> = {}

export function useBalance(): Balance {
  const lavarages = useLavarage()
  const lavarage = lavarages[0]
  const [userBalance, setUserBalance] = usePreferencesStore(useShallow(s => [s.userBalance, s.setUserBalance]))
  const { connected, connecting, publicKey } = useWallet()

  useEffect(() => {
    if (!lavarage || !publicKey || !connected) {
      setUserBalance({ SOL: null, USDC: null })
      return
    }

    const { connection } = lavarage.program.provider

    const fetchBalances = async () => {
      try {
        const lamports = await connection.getBalance(publicKey)
        const sol = formatLamportsToSol(lamports).toNumber()

        const usdcMintAddress = new PublicKey(USDC_ADDRESS)
        const usdcAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          mint: usdcMintAddress,
        })
        const usdcBalance =
          usdcAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

        setUserBalance({ SOL: sol, USDC: usdcBalance })
      }
      catch (error) {
        console.error('Error fetching balances:', error)
        setUserBalance({ SOL: null, USDC: null })
      }
    }

    fetchBalances()

    const pubKeyString = publicKey.toString()

    if (!activeSubscriptions[pubKeyString]) {
      const subscriptionId = connection.onAccountChange(
        publicKey,
        accountInfo => {
          if (accountInfo.lamports !== undefined) {
            const updatedSol = formatLamportsToSol(accountInfo.lamports).toNumber()
            setUserBalance({ SOL: updatedSol, USDC: userBalance?.USDC || null })
          }
        },
        'confirmed',
      )

      activeSubscriptions[pubKeyString] = subscriptionId
    }

    return () => {
      if (activeSubscriptions[pubKeyString]) {
        connection.removeAccountChangeListener(activeSubscriptions[pubKeyString])
        delete activeSubscriptions[pubKeyString]
      }
    }
  }, [lavarage, publicKey, connected, setUserBalance])

  return { userBalance, connected, connecting, publicKey }
}
