import { type ActionMainButtonMode } from '@/app/pages/main/components/ActionMainButton'
import { stringToNumber } from '@/utils'
import BigNumber from 'bignumber.js'

type GetActionButtonModeProps = {
  isOnline: boolean
  connected: boolean
  confirming: boolean
  solInput: string
  maxAvailableFunds: number
  leverage: number
  borrowedAmount: number
  availableLiquidity: number
  loading: boolean
}

export const getActionMainButtonMode = ({
  isOnline,
  connected,
  confirming,
  solInput,
  maxAvailableFunds,
  leverage,
  borrowedAmount,
  availableLiquidity,
  loading,
}: GetActionButtonModeProps): ActionMainButtonMode => {
  const numberAmount = stringToNumber(solInput)
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case !connected:
      return 'connect-wallet'
    case confirming:
      return 'confirming'
    case solInput === '' || stringToNumber(solInput) === 0:
      return 'enter-amount'
    case stringToNumber(solInput) > maxAvailableFunds:
      return 'insufficient-funds'
    case leverage !== 1 && BigNumber(borrowedAmount).gt(BigNumber(availableLiquidity)):
      return 'no-liquidity'
    case leverage !== 1 && numberAmount !== 0 && !confirming && !loading:
      return 'trade'
    case leverage === 1 && numberAmount !== 0 && !confirming && !loading:
      return 'swap'
    default:
      return 'restricted'
  }
}
